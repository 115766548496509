import React from 'react'

import { Layout, SEO } from '../../components/common'
import { Social } from '../../components/common/Social'
import { TelegramGroup } from '../../components/common/Promo'

// Styles
import '../../styles/app.css'

const Index = ({ data, location, pageContext }) => {
    return (
        <>
            <Layout isHome={false} bgImage="/images/northfpv_1.jpg" promotion={<TelegramGroup/>}>
                <SEO
                    title="Cos'è North FPV?"
                    description="North FPV è una community FPV Italiana, creata per insegnare e condividere le emozioni del volo in FPV."
                />
                <div className="container">
                    <h1>Cos'è North FPV?</h1>
                    <p>North FPV è una community FPV Italiana, creata per insegnare e condividere le emozioni del volo in FPV. Se ti trovi in Lombardia e vuoi volare insieme a noi, scrivici subito! <span role="img" aria-label="smile">😁</span></p>
                </div>
                <div className="flex-container">
                    <div className="flex-item mt-2">
                        <p>Cerchiamo di rispondere a ogni domanda che riceviamo. Aiutiamo quasi tutti senza richiedere alcun pagamento.</p>
                        <p>Se vuoi farci una domanda relativa a FPV o hai un dubbio, contattaci utilizzando uno di questi metodi e cercheremo di aiutarti.</p>
                        <p>Se pensi che il nostro lavoro sia utile, <a href="/supportaci">ecco come puoi sostenerci.</a></p>
                    </div>
                    <div className="flex-item">
                        <div className="social-list-container">
                            <h3>Seguici sui social</h3>
                            <Social isWhite={false}/>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
